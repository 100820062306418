<template>
  <div>
    <v-row>
      <v-col sm="12" md="3">
        <v-checkbox
          v-model="includePivotLocal"
          label="Export With Pivot Table"
        ></v-checkbox>
      </v-col>
      <v-col sm="12" md="3" v-if="includePivotLocal">
        <h4 @click="togglePivotDetails" class="pointer primary--text">
          {{ pivotText }}
        </h4>
      </v-col>
      <v-col sm="12" md="3" v-if="includePivotLocal && pivotDetails">
        <v-checkbox
          v-model="pivotFirstLocal"
          label="Pivot sheet first"
        ></v-checkbox>
      </v-col>
      <v-col sm="12" md="3" v-if="includePivotLocal && pivotDetails">
        <v-text-field
          v-model="pivotNameLocal"
          label="pivot name"
          hint="sheet name for pivot table"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="includePivotLocal && pivotDetails">
      <v-col sm="12" md="3">
        <v-select
          v-model="filterFieldsLocal"
          :items="availablePivotFilterFields"
          label="Report Filter Fields"
          item-text="text"
          item-value="value"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        ></v-select>
      </v-col>
      <v-col sm="12" md="3">
        <v-select
          v-model="rowFieldsLocal"
          :items="availablePivotRowFields"
          label="Row Fields"
          item-text="text"
          item-value="value"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        ></v-select>
      </v-col>
      <v-col sm="12" md="3">
        <v-select
          v-model="columnFieldsLocal"
          :items="availablePivotColumnFields"
          label="Column Fields"
          item-text="text"
          item-value="value"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        ></v-select>
      </v-col>
      <v-col sm="12" md="3">
        <v-select
          v-model="excludeFieldsLocal"
          :items="availablePivotExcludeFields"
          label="Exclude Fields"
          item-text="text"
          item-value="value"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        ></v-select>
      </v-col>
      <v-col sm="12" md="3">
        <v-list-item>
          <v-list-item-content
            class="v-label"
            v-if="dataFieldsLocal.length == 0"
            >Data Fields</v-list-item-content
          >
          <v-list-item-content
            class="v-label v-label--active"
            style="
              left: 0px;
              right: auto;
              position: absolute;
              transform: translateY(-18px) scale(0.75);
            "
            v-if="dataFieldsLocal.length > 0"
            >Data Fields</v-list-item-content
          >
          <v-chip-group multiple column>
            <v-chip
              pill
              small
              v-for="(field, index) in dataFieldsLocal"
              :key="index"
              close
              @click:close="removeDataField(field)"
              >{{ field }}</v-chip
            >
          </v-chip-group>
          <v-list-item-icon>
            <v-icon @click="dataFieldModal = true">add</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dataFieldModal" width="350" scrollable>
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">Add data field to pivot</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12" md="6">
              <v-select
                v-model="selectedDataField"
                :items="filteredHeaders"
                label="Data Field"
                item-text="text"
                item-value="value"
                dense
              ></v-select>
            </v-col>
            <v-col sm="12" md="6">
              <v-select
                v-model="selectedDataType"
                :items="availableDataTypes"
                label="Summary Type"
                item-text="text"
                item-value="value"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="!selectedDataField || !selectedDataType"
            @click="addDataField"
            >Add</v-btn
          >
          <v-btn @click="dataFieldModal = false" outlined color="primary"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  model: {},
  props: {
    includePivot: {
      type: Boolean,
      default: false,
    },
    pivotFirst: {
      type: Boolean,
      default: true,
    },
    pivotName: {
      type: String,
      default: '',
    },
    rowFields: {
      type: Array,
      default: () => [],
    },
    columnFields: {
      type: Array,
      default: () => [],
    },
    dataFields: {
      type: Array,
      default: () => [],
    },
    excludeFields: {
      type: Array,
      default: () => [],
    },
    filterFields: {
      type: Array,
      default: () => [],
    },
    filteredHeaders: Array,
  },
  components: {},
  filters: {},
  data() {
    return {
      pivotDetails: true,
      pivotText: '-Hide Details-',
      dataFieldModal: false,
      selectedDataField: '',
      selectedDataType: '',
      availableDataTypes: [
        {text: 'Sum', value: 'sum'},
        {text: 'Percent', value: 'pcnt'},
        {text: 'Count', value: 'count'},
        {text: 'Average', value: 'avg'},
        {text: 'Max', value: 'max'},
        {text: 'Min', value: 'min'},
        {text: 'Product', value: 'pdct'},
        {text: 'Count Numbers', value: 'cntNum'},
        {text: 'StdDev', value: 'stdev'},
        {text: 'StdDevp', value: 'stdevp'},
        {text: 'Var', value: 'var'},
        {text: 'Varp', value: 'varp'},
        // {text: 'None', value: 'none'},
      ],
    }
  },
  created() {},
  computed: {
    pivotNameLocal: {
      get: function () {
        return JSON.parse(JSON.stringify(this.pivotName))
      },
      set: function (value) {
        this.$emit('update:pivotName', value)
      },
    },
    pivotFirstLocal: {
      get: function () {
        return JSON.parse(JSON.stringify(this.pivotFirst))
      },
      set: function (value) {
        this.$emit('update:pivotFirst', value)
      },
    },
    includePivotLocal: {
      get: function () {
        return JSON.parse(JSON.stringify(this.includePivot))
      },
      set: function (value) {
        this.$emit('update:includePivot', value)
      },
    },
    filterFieldsLocal: {
      get: function () {
        return JSON.parse(JSON.stringify(this.filterFields))
      },
      set: function (value) {
        this.$emit('update:filterFields', value)
      },
    },
    excludeFieldsLocal: {
      get: function () {
        return JSON.parse(JSON.stringify(this.excludeFields))
      },
      set: function (value) {
        this.$emit('update:excludeFields', value)
      },
    },
    dataFieldsLocal: {
      get: function () {
        return JSON.parse(JSON.stringify(this.dataFields))
      },
      set: function (value) {
        this.$emit('update:dataFields', value)
      },
    },
    rowFieldsLocal: {
      get: function () {
        return JSON.parse(JSON.stringify(this.rowFields))
      },
      set: function (value) {
        this.$emit('update:rowFields', value)
      },
    },
    columnFieldsLocal: {
      get: function () {
        return JSON.parse(JSON.stringify(this.columnFields))
      },
      set: function (value) {
        this.$emit('update:columnFields', value)
      },
    },
    availablePivotColumnFields() {
      let list = JSON.parse(JSON.stringify(this.filteredHeaders))
      this.filterFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.rowFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.excludeFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      return list
    },
    availablePivotRowFields() {
      let list = JSON.parse(JSON.stringify(this.filteredHeaders))
      this.filterFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.columnFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.excludeFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      return list
    },
    availablePivotFilterFields() {
      let list = JSON.parse(JSON.stringify(this.filteredHeaders))
      this.rowFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.columnFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.excludeFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      return list
    },
    availablePivotExcludeFields() {
      let list = JSON.parse(JSON.stringify(this.filteredHeaders))
      this.filterFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.rowFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.columnFieldsLocal.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      return list
    },
  },
  methods: {
    togglePivotDetails() {
      this.pivotDetails = !this.pivotDetails
      if (this.pivotDetails) {
        this.pivotText = '-Hide Details-'
      } else {
        this.pivotText = '-Show Details-'
      }
    },
    removeDataField(field) {
      var list = JSON.parse(JSON.stringify(this.dataFieldsLocal))
      let index = list.indexOf(field)
      list.splice(index, 1)
      this.dataFieldsLocal = list
    },
    addDataField() {
      var list = JSON.parse(JSON.stringify(this.dataFieldsLocal))
      list.push(this.selectedDataField + '|' + this.selectedDataType)
      this.dataFieldsLocal = list
      this.selectedDataField = ''
      this.selectedDataType = ''
      this.dataFieldModal = false
    },
  },
  watch: {
    filteredHeaders: {
      handler(nval, oval) {
        if (this.filterFieldsLocal) {
          let filterFields = this.filterFieldsLocal.filter((item) => {
            return nval.find((v) => v.value == item)
          })
          this.filterFieldsLocal = filterFields
        }
        if (this.rowFieldsLocal) {
          let rowFields = this.rowFieldsLocal.filter((item) => {
            return nval.find((v) => v.value == item)
          })
          this.rowFieldsLocal = rowFields
        }
        if (this.columnFieldsLocal) {
          let columnFields = this.columnFieldsLocal.filter((item) => {
            return nval.find((v) => v.value == item)
          })
          this.columnFieldsLocal = columnFields
        }
        if (this.dataFieldsLocal) {
          let dataFields = this.dataFieldsLocal.filter((item) => {
            let fieldName = item.split('|')[0]
            return nval.find((v) => v.value == fieldName)
          })
          this.dataFieldsLocal = dataFields
        }
        if (this.excludeFieldsLocal) {
          let excludeFields = this.excludeFieldsLocal.filter((item) => {
            return nval.find((v) => v.value == item)
          })
          this.excludeFieldsLocal = excludeFields
        }
      },
    },
  },
}
</script>
<style scoped>
</style>