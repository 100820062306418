import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"},on:{"click":function($event){_vm.reorderFieldDialog = true}}},[_vm._v("reorder")]),_c(VDialog,{attrs:{"scrollable":"","width":"450"},model:{value:(_vm.reorderFieldDialog),callback:function ($$v) {_vm.reorderFieldDialog=$$v},expression:"reorderFieldDialog"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"primary"},[_c('h3',{staticClass:"hpHeading white--text"},[_vm._v("Reorder Fields")])]),_c(VCardText,{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"sm":"12","md":"12"}},[_c('draggable',{model:{value:(_vm.fields),callback:function ($$v) {_vm.fields=$$v},expression:"fields"}},_vm._l((_vm.fields),function(field,index){return _c('div',{key:index},[_c('span',{staticStyle:{"cursor":"move"}},[_vm._v(_vm._s(field))])])}),0)],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.reorderFieldDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }